<template>
  <main class="bg-light">
    <div>
      <keep-alive>
        <top-nav/>
      </keep-alive>
    </div>
    <div class="mb-3">
      <router-view
          v-on:load="load"
      />
    </div>
    <div v-if="pending">
      <b-spinner
          size="lg"
          variant="danger"
      />
    </div>
    <div v-else>
      <b-container class="mt-4">
        <b-row>
          <b-col>
            <b-table-simple small responsive="" bordered class="bg-white">
              <b-thead>
                <b-tr>
                  <b-th>
                    type
                  </b-th>
                  <b-th>
                    TITLE
                  </b-th>
                  <b-th>
                    등록 ID
                  </b-th>
                  <b-th>
                    DATE
                  </b-th>
                  <b-th>
                    top 여부
                  </b-th>
                  <b-th>
                    노출 여부
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                    v-for="q in list"
                    :key="q._id"
                >
                  <b-td>
                    <div>
                      {{ q.type === 'event' ? '이벤트' : '공지사항' }}
                    </div>
                  </b-td>
                  <b-td class="text-center">
                    <div class="d-block">
                      <p
                          class="text-truncate my-1"
                          style="font-size: 0.8rem"
                      >
                        <router-link :to="`/notice/${q._id}`">
                          {{ q.title }}
                        </router-link>
                      </p>
                    </div>
                  </b-td>
                  <b-td>
                    <div>
                      {{ q.regUserId }}
                    </div>
                  </b-td>
                  <b-td>
                    <div>
                      {{ new Date(q.regDate).toLocaleString() }}
                    </div>
                  </b-td>
                  <b-td>
                    <div>
                      {{q.top ? '활성화' : '비활성화'}}
                    </div>
                  </b-td>
                  <b-td>
                    <div>
                      {{q.use ? '노출' : '숨김'}}
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <div class="d-flex justify-content-end mb-2">
              <b-button
                  to="/notice/new"
                  variant="danger"
                  size="sm"
                  squared
              >
                <font-awesome-icon icon="plus"/> 등록
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-pagination-nav
                v-if="list.length"
                :link-gen="linkGen"
                v-model="page"
                :number-of-pages="numberOfPages"
                use-router
                aria-controls="my-table"
                first-number
                last-number
                first-text="«"
                prev-text="‹"
                next-text="›"
                last-text="»"
                align="right"
                size="sm"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </main>
</template>

<script>
export default {
  name: "notice",
  data: () => ({
    pending: false,
    drag: false,
    list: [],
    page: 1,
    total: 0,
    perPage: 0,
    numberOfPages: 0,
    filterData: {
      type: null,
      search: null
    },
  }),
  created () {
    (async () => {
      const {_id} = this.$route.params
      if (_id) this.selectedId = _id
      const { page, searchFilter, searchValue, perPage } = this.$route.query
      if (page && !isNaN(+page)) this.page = +page
      if (searchFilter) this.filter.searchFilter = searchFilter
      if (searchValue) this.filter.searchValue = searchValue
      if (perPage && !isNaN(+perPage)) this.filter.perPage = +perPage
      await this.load()
    })()
  },
  methods: {
    async load () {
      this.pending = true
      console.log(this.page)
      try {
        const {data} = await this.axios({
          url: `/admin/notice/list`,
          params: {
            page: this.page,
            ...this.filter
          }
        })
        const {result, notice, total, perPage, page, selectedData, totalPage} = data
        if (result) {
          this.list = notice
          this.total = total
          this.page = page
          this.perPage = perPage
          this.numberOfPages = totalPage
        }
      } catch (e) {
      }
      this.pending = false
    },
    async save () {
      try {
        const {data} = await this.axios({
          url: '/support/faq',
          method: 'post',
          data: {
            list: this.list
          }
        })

        const {result} = data
        if(result) {
          this.$bvToast.toast(`순서가 변경되었습니다.`, {
            title: 'SUCCESS',
            autoHideDelay: 1000,
            variant: 'success',
            appendToast: false
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    linkGen (page) {
      return `${this.$route.path}?page=${page}&${Object.entries(this.filterData).map(v => v[1] ? `${v[0]}=${v[1]}` : `${v[0]}`).join('&')}`
    },
  },
  computed: {
    dragList () {
      if (this.list === undefined) return
      return this.list.map(item => {
        return {_id: item._id, name: item.title, order: item.ord};
      })
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
}
</script>

<style scoped>
  main {
    min-height: 100vh;
  }
  th, td {
    vertical-align: middle;
    font-size: 0.8rem;
  }
  tr.active {
    color: red;
    font-weight: bold;
    background-color: #F5F5F5;
  }

  .button {
    margin-top: 35px;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>
